<template>
  <v-dialog
    v-model="value"
    max-width="600px"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h3 lighten-2 pb-5"
      >
        POLÍTICA DE COOKIES E REGISTRO DE NAVEGAÇÃO
      </v-card-title>
      <v-divider />
      <v-card-text
        v-html="termos"
      />
      <v-divider />
      <v-card-actions class="justify-center">
        <v-btn
          color="blue lighten-1"
          @click="$emit('fecharAlerta')"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'LgpdDialog',
    props: {
      value: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data: () => ({
      termos: '',
    }),
    created () {
      this.lgpd()
    },
    methods: {
      async lgpd () {
        const lgpd = './uploads/lgpd.txt'
        var textoLgpd = await (await fetch(lgpd)).text()
        this.termos = textoLgpd
      },
    },
  }
</script>
